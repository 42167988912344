const CustomPage = ({ questions, answers, onChange, customContent, title, copy }) => {
  return (
    <div>
      <h2 className='mb-5 text-[50px] uppercase'>{title}</h2>
      <p className='mb-16'>{copy}</p>
      {questions.map((q, index) => (
        <div key={index} className='mb-10'>
          <h3 className='mb-2 font-bold'>{q.question}</h3>
          <input
            type="text"
            name={q.name}
            value={answers[q.name] || ''}
            onChange={onChange}
            className='w-full p-4'
          />
        </div>
      ))}
      {customContent}
    </div>
  );
};

export default CustomPage;