const Navigation = ({ pages, currentPage, goToPage, title, scrollBack }) => {
  return (
    <nav>
      <ul className='flex flex-col text-white text-[22px]'>
        {pages.map((page, index) => (
          <li
            key={index}
            style={{
              marginRight: '10px',
              cursor: 'pointer',
              fontWeight: currentPage === index ? 'bold' : 'normal',
            }}
            onClick={() => {
              goToPage(index);
              scrollBack();
            }}
            className='flex flex-row items-center mb-3 uppercase'
          >
            <div 
              className={`!w-10 !h-10 border rounded-full flex items-center justify-center mr-3 opacity-60 nav 
                ${currentPage === index ? 'border-orange !opacity-100':''} 
                ${currentPage >= index+1 ? 'bg-orange border-orange !opacity-100':''}`}
              >
                {currentPage <= index &&
                  <span>{index+1}</span>
                }
                {currentPage >= index +1 &&
                  <svg width="15" height="12" viewBox="0 0 15 12" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 1.86029L5.29668 12L0 6.46511L1.78029 4.60475L5.29668 8.27935L13.2198 0L15 1.86029Z" fill="#181818"/>
                  </svg>
                }
            </div>
            <span className={`nav ${currentPage+1 >= index+1 ? '':'opacity-60'}`}>{page.props.title}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;