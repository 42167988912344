
const QuestionPage = ({ questions, answers, onChange, title, copy }) => {
  return (
    <div>
      <h2 className='mb-6 text-[36px] md:text-[50px] leading-10 mt-5 md:leading-none uppercase'>{title}</h2>
      <p className='mb-16'>{copy}</p>
      {questions.map((q, index) => (
        <div key={index} className='mb-10'>
          <h3 className='mb-2 font-bold'>{q.question}</h3>
          {q.copy && <span className='mb-4 flex text-sm opacity-60'>{q.copy}</span>}
          {q.type === 'text' && (
            <input
              type="text"
              name={q.name}
              value={answers[q.name] || ''}
              onChange={onChange}
              className='w-full p-4 rounded-md accent-orange outline-orange'
            />
          )}
          {q.type === 'textarea' && (
            <textarea
              name={q.name}
              value={answers[q.name] || ''}
              onChange={onChange}
              rows={q.rows || 3}
              className='w-full p-4 rounded-md accent-orange outline-orange'
            />
          )}
          {q.type === 'radio' && (
            <div>
              {q.options.map((option, idx) => (
                <label key={idx} className='flex flex-row gap-2 mb-2 items-center'>
                  <input
                    type="radio"
                    name={q.name}
                    value={option}
                    checked={answers[q.name] === option}
                    onChange={onChange}
                    className='w-4 h-4 accent-orange'
                  />
                  {option}
                </label>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionPage;